import React, { useState, useEffect, useContext } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { v4 as uuid } from 'uuid'
import moment from 'moment'

// Assets
import MenuIcon from 'assets/images/layer.svg'
import logoIcon from 'assets/images/Layer.png'

// Constants
import { DASHED_DATE_FORMAT } from 'common/constants/dateFormat'
import { Context } from 'common/context/Context'

// Store
import { actions } from 'core/store'

// Styled Elements
import { TransactionsTableWrapper } from './TransactionsTable.elements'

// Views
import { AccordionTable, DateRangePickerSet, EmptyTablePlaceholder, Image } from 'views/components'
import { TransactionsTableItem } from 'views/layouts'

// Map Redux Props
const mapStateToProps = () => ({})
const mapDispatchToProps = (dispatch) => actions(dispatch)

const TransactionsTable = (props) => {
  const { refreshTableData } = useContext(Context)

  // Destructure
  const { data, onChange, headerOptions } = props

  // Variables
  const tableHeaders = [
    { name: 'Transaction', sort: true, identifier: 'Address', width: 250 },
    { name: 'Asset', sort: true, identifier: 'Asset' },
    { name: 'Amount', sort: true, identifier: 'Input_USD' },
    { name: 'Risk Score', sort: true, identifier: 'Transaction_Risk_Score' },
    { name: 'Timestamp', sort: true, identifier: 'CreateTimestamp' },
  ]

  // States
  const [tableFilters, setTableFilters] = useState({
    start: moment().subtract(30, 'days').format(DASHED_DATE_FORMAT),
    end: moment().add(1, 'days').format(DASHED_DATE_FORMAT),
  })

  // Functions
  const handleTableChange = (args) => {
    setTableFilters((prevState) => ({ ...prevState, ...args }))
  }

  const handleOnTableFilterChange = (args) => {
    setTableFilters((prevState) => ({ ...prevState, ...args }))
  }

  // useEffect
  useEffect(() => {
    onChange(tableFilters)
  }, [tableFilters])

  return (
    <TransactionsTableWrapper>
      <AccordionTable
        title="Transactions Table"
        titleIcon={<img src={logoIcon} width={30} height={30} />}
        headers={tableHeaders}
        minWidth={700}
        totalItems={data.total && data.total[0]}
        filterComponents={<DateRangePickerSet onApply={handleOnTableFilterChange} />}
        headerOptions={headerOptions}
        onChange={handleTableChange}
      >
        {data.biz_total && data.items.length > 0 || refreshTableData === true ? (
          typeof data.items==='object' && data.items.map(
            ({
              Transaction_ID,
              Hash,
              Asset,
              Transaction_Risk_Score,
              CreateTimestamp,
              Tx_Timestamp,
              Block_ID,
              Flags,
              Created_By,
              Case_ID,
              Input_Count,
              Input_USD,
              Output_Count,
              Output_USD,
            }) => (
              <TransactionsTableItem
                key={uuid()}
                id={Transaction_ID}
                transaction={Hash}
                asset={Asset}
                blockId={Block_ID}
                riskScore={Transaction_Risk_Score}
                flags={Flags}
                createdBy={Created_By}
                createTimestamp={CreateTimestamp}
                timeStamp={Tx_Timestamp}
                incoming={Input_Count}
                incomingUsd={Input_USD}
                outgoing={Output_Count}
                outgoingUsd={Output_USD}
                caseId={Case_ID}
              />
            )
          )
        ) : (
          <EmptyTablePlaceholder />
        )}
      </AccordionTable>
    </TransactionsTableWrapper>
  )
}

// Default Props
TransactionsTable.defaultProps = {
  actions: {},
  data: {},
  onChange: () => {},
  headerOptions: '',
}

// Proptypes Validation
TransactionsTable.propTypes = {
  actions: PropTypes.shape({ openAssignToCaseDrawer: PropTypes.func }),
  onChange: PropTypes.func,
  data: PropTypes.shape({
    items: PropTypes.instanceOf(Array),
    total: PropTypes.instanceOf(Array),
    biz_total: PropTypes.instanceOf(Array),
  }),
  headerOptions: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.node]),
}

export default connect(mapStateToProps, mapDispatchToProps)(TransactionsTable)
