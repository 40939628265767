import React, { useEffect, useContext, useState } from 'react'
import PropTypes from 'prop-types'
import { useParams } from 'react-router-dom'
import { connect } from 'react-redux'

// Library Components
import { Layout } from 'antd'

// Store
import { actions } from 'core/store'

// Hooks
import { useFetchCaseCustomer, useFetchCaseAddresses } from 'core/hooks/api'
import { Context } from 'common/context/Context'

// Styled Components
import { AddressesWrapper, CaseStatusSummaryWrapper } from './Addresses.elements'

// Views
import { Container, Button } from 'views/components'

import { App, Header, Sidebar, Content, CasesSubHeader, CaseStatusSummary, AddressesTable } from 'views/layouts'

// Map Redux Props
const mapStateToProps = () => ({})
const mapDispatchToProps = (dispatch) => actions(dispatch)

const Addresses = (props) => {
  const {
    dateRange,
    pageIndex,
    pageSize,
    sortBy,
    sortDir,
    updatePageIndex,
    updateSortBy,
    updateSortDir,
    updateAddressData,
    refreshAddress,
  } = useContext(Context)

  // Destructure
  const { actions } = props

  // Store Actions
  const { toggleUploadCaseModal, setActiveModule, setActiveCase } = actions

  // Hooks
  const { customerId } = useParams()
  const { caseCustomerData, getCaseCustomer } = useFetchCaseCustomer()
  const { caseAddressesData, getCaseAddresses } = useFetchCaseAddresses()
  const [idData, setIdData] = useState({})
  // Functions
  const handleOpenUploadCaseModal = () => {
    toggleUploadCaseModal()
  }

  // useEffect
  useEffect(() => {
    setActiveModule('addresses')
  }, [])

  useEffect(() => {
    if (customerId) {
      getCaseCustomer({ customer_id: customerId })
    }
  }, [customerId, refreshAddress])

  useEffect(() => {
    // const { sortBy, sortDirection, pageIndex, pageSize } = params
    if (caseCustomerData) {
      getCaseAddresses({
        case_id: caseCustomerData.Case_ID,
        start_range: dateRange.startDate,
        end_range: dateRange.endDate,
        sortby: sortBy,
        sortdir: sortDir,
        pageindex: pageIndex - 1,
        pagesize: pageSize,
      })
      updateSortBy('CreateTimestamp')
      updatePageIndex(1)
      updateSortDir('DESC')
      setActiveCase(caseCustomerData.Case_ID)
      updateAddressData(false)
      setIdData(caseCustomerData)
    }
  }, [caseCustomerData, refreshAddress])

  return (
    <App>
      <Sidebar />
      <Layout>
        <Header subHeader={<CasesSubHeader />} />
        <Content>
          <AddressesWrapper>
            <Container>
              <CaseStatusSummaryWrapper>
                {/* {caseCustomerData && ( */}
                <CaseStatusSummary
                  customerId={caseCustomerData?.Customer_ID || idData?.Customer_ID}
                  total={caseCustomerData?.AddressCount || idData?.AddressCount}
                  status={caseCustomerData?.Case_Status || idData?.Case_Status}
                  type="address"
                  caseType={caseCustomerData?.Case_Type || idData?.Case_Type}
                />
                {/* )} */}
              </CaseStatusSummaryWrapper>
            </Container>
            <Container>
              {/* {caseAddressesData && ( */}
              <AddressesTable
                data={caseAddressesData && caseAddressesData}
                headerOptions={
                  <Button variant="outlined" onClick={handleOpenUploadCaseModal}>
                    Import
                  </Button>
                }
              />
              {/* )} */}
            </Container>
          </AddressesWrapper>
        </Content>
      </Layout>
    </App>
  )
}

// Default Props
Addresses.defaultProps = {
  actions: {},
}

// Proptypes Validation
Addresses.propTypes = {
  actions: PropTypes.shape({
    toggleUploadCaseModal: PropTypes.func,
    setActiveModule: PropTypes.func,
    setActiveCase: PropTypes.func,
  }),
}

export default connect(mapStateToProps, mapDispatchToProps)(Addresses)
