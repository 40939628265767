import React, { useState, useEffect, useContext } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { v4 as uuid } from 'uuid'
import moment from 'moment'

// Assets
import MenuIcon from 'assets/images/layer.svg'
import logoIcon from 'assets/images/Layer.png'

// Constants
import { DASHED_DATE_FORMAT } from 'common/constants/dateFormat'
import { Context } from 'common/context/Context'

// Store
import { actions } from 'core/store'

// Styled Elements
import { AddressesTableWrapper } from './AddressesTable.elements'

// Views
import { AccordionTable, DateRangePickerSet, EmptyTablePlaceholder, Image } from 'views/components'

import { AddressesTableItem } from 'views/layouts'

// Map Redux Props
const mapStateToProps = () => ({})
const mapDispatchToProps = (dispatch) => actions(dispatch)

const AddressesTable = (props) => {
  const { refreshAddress } = useContext(Context)

  // Destructure
  const { data, onChange, headerOptions } = props
  // Variables
  const tableHeaders = [
    { name: 'Address', sort: true, identifier: 'Address', width: 250 },
    { name: 'Asset', sort: true, identifier: 'Asset' },
    { name: 'Balance', sort: true, identifier: 'Balance' },
    { name: 'Risk Score', sort: true, identifier: 'Address_Risk_Score' },
    { name: 'Timestamp', sort: true, identifier: 'CreateTimestamp' },
  ]

  // States
  const [tableFilters, setTableFilters] = useState({
    start: moment().subtract(30, 'days').format(DASHED_DATE_FORMAT),
    end: moment().add(1, 'days').format(DASHED_DATE_FORMAT),
  })

  // Functions
  const handleTableChange = (args) => {
    setTableFilters((prevState) => ({ ...prevState, ...args }))
  }

  const handleOnTableFilterChange = (args) => {
    setTableFilters((prevState) => ({ ...prevState, ...args }))
  }

  // useEffect
  useEffect(() => {
    onChange(tableFilters)
  }, [tableFilters])


  return (
    <AddressesTableWrapper>
      <AccordionTable
        title="Addresses Table"
        titleIcon={<img src={logoIcon} width={30} height={30} />}
        headers={tableHeaders}
        minWidth={700}
        totalItems={data.total && data.total[0]}
        filterComponents={<DateRangePickerSet onApply={handleOnTableFilterChange} />}
        headerOptions={headerOptions}
        onChange={handleTableChange}
      >
        {(data.biz_total && data.items.length > 0) || refreshAddress === true ? (
         typeof data.items==='object' && data.items.map(
            ({
              Address_ID,
              Address,
              Asset,
              Balance_USD,
              Address_Risk_Score,
              CreateTimestamp,
              Latest_Activity,
              Flags,
              Balance,
              Created_By,
              Case_ID,
              Received,
              Received_USD,
              Spent,
              Spent_USD,
            }) => (
              <AddressesTableItem
                key={uuid()}
                id={Address_ID}
                address={Address}
                asset={Asset}
                balanceUsd={Balance_USD}
                balance={Balance}
                riskScore={Address_Risk_Score}
                flags={Flags}
                createdBy={Created_By}
                latestActivity={Latest_Activity}
                timestamp={CreateTimestamp}
                incoming={Received}
                incomingUsd={Received_USD}
                outgoing={Spent}
                outgoingUsd={Spent_USD}
                caseId={Case_ID}
              />
            )
          )
        ) : (
          <EmptyTablePlaceholder />
        )}
      </AccordionTable>
    </AddressesTableWrapper>
  )
}

// Default Props
AddressesTable.defaultProps = {
  actions: {},
  data: {},
  onChange: () => {},
  headerOptions: '',
}

// Proptypes Validation
AddressesTable.propTypes = {
  actions: PropTypes.shape({ openAssignToCaseDrawer: PropTypes.func }),
  onChange: PropTypes.func,
  data: PropTypes.shape({
    items: PropTypes.instanceOf(Array),
    total: PropTypes.instanceOf(Array),
    biz_total: PropTypes.instanceOf(Array),
  }),
  headerOptions: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.node]),
}

export default connect(mapStateToProps, mapDispatchToProps)(AddressesTable)
