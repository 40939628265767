import React, { useEffect, useContext, useState } from 'react'
import PropTypes from 'prop-types'
import { useParams } from 'react-router-dom'
import { connect } from 'react-redux'

// Library Components
import { Layout } from 'antd'

// Store
import { actions } from 'core/store'

// Hooks
import { useFetchCaseCustomer, useFetchCaseTransactions } from 'core/hooks/api'
import { Context } from 'common/context/Context'

// Styled Components
import { TransactionsWrapper, CaseStatusSummaryWrapper } from './Transactions.elements'

// Views
import { Container, Button } from 'views/components'

import { App, Header, Sidebar, Content, CasesSubHeader, CaseStatusSummary, TransactionsTable } from 'views/layouts'

// Map Redux Props
const mapStateToProps = () => ({})
const mapDispatchToProps = (dispatch) => actions(dispatch)

const Transactions = (props) => {
  // context
  const {
    dateRange,
    pageIndex,
    pageSize,
    sortBy,
    sortDir,
    updatePageIndex,
    updateSortBy,
    updateSortDir,
    refreshTable,
    refreshTableData,
  } = useContext(Context)
  // Destructure
  const { actions } = props

  // Store Actions
  const { toggleUploadCaseModal, setActiveModule, setActiveCase } = actions

  // Hooks
  const { customerId } = useParams()
  const { caseCustomerData, getCaseCustomer } = useFetchCaseCustomer()
  const { caseTransactionsData, getCaseTransactions } = useFetchCaseTransactions()
  const [idData, setIdData] = useState({})

  // Functions
  const handleOpenUploadCaseModal = () => {
    toggleUploadCaseModal()
  }

  // useEffect
  useEffect(() => {
    setActiveModule('transactions')
  }, [])

  useEffect(() => {
    if (customerId) {
      getCaseCustomer({ customer_id: customerId })
    }
  }, [customerId, refreshTableData])

  useEffect(() => {
    if (caseCustomerData) {
      getCaseTransactions({
        case_id: caseCustomerData.Case_ID,
        start_range: dateRange.startDate,
        end_range: dateRange.endDate,
        sortby: sortBy,
        sortdir: sortDir,
        pageindex: pageIndex - 1,
        pagesize: pageSize,
      })
      updateSortBy('CreateTimestamp')
      updatePageIndex(1)
      updateSortDir('DESC')
      setIdData(caseCustomerData);
      setActiveCase(caseCustomerData.Case_ID)
      refreshTable(false)
    }
  }, [caseCustomerData, refreshTableData])

  return (
    <App>
      <Sidebar />
      <Layout>
        <Header subHeader={<CasesSubHeader />} />
        <Content>
          <TransactionsWrapper>
            <Container>
              <CaseStatusSummaryWrapper>
                {/* {caseCustomerData && ( */}
                  <CaseStatusSummary
                    customerId={caseCustomerData?.Customer_ID||idData?.Customer_ID}
                    total={caseCustomerData?.TxCount||idData?.TxCount}
                    status={caseCustomerData?.Case_Status||idData?.Case_Status}
                    type="transaction"
                    caseType={caseCustomerData?.Case_Type||idData?.Case_Type}
                  />
                {/* )} */}
              </CaseStatusSummaryWrapper>
            </Container>
            <Container>
              {/* {caseTransactionsData && ( */}
                <TransactionsTable
                  data={caseTransactionsData && caseTransactionsData}
                  headerOptions={
                    <Button variant="outlined" onClick={handleOpenUploadCaseModal}>
                      Import
                    </Button>
                  }
                />
              {/* )} */}
            </Container>
          </TransactionsWrapper>
        </Content>
      </Layout>
    </App>
  )
}

// Default Props
Transactions.defaultProps = {
  actions: {},
}

// Proptypes Validation
Transactions.propTypes = {
  actions: PropTypes.shape({
    toggleUploadCaseModal: PropTypes.func,
    setActiveModule: PropTypes.func,
    setActiveCase: PropTypes.func,
  }),
}

export default connect(mapStateToProps, mapDispatchToProps)(Transactions)
