import React, { useEffect, useState, useContext } from 'react'
import { NavLink } from 'react-router-dom'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { saveAs } from 'file-saver'
import xlsx from 'xlsx'
import moment from 'moment'

// Assets
import BrandLogo from 'assets/images/brand-logo.png'
import UserIcon from 'assets/images/user.svg'
import UserCircledIcon from 'assets/images/user-icon.svg'
import LogoutIcon from 'assets/images/log-out.svg'
import MenuIcon from 'assets/images/menu-icon.svg'
import TabPaneIcon from 'assets/images/tab-pane.svg'
import NotificationIcon from 'assets/images/notification-bell.svg'

// Contants
import { EXTERNAL_URLS } from 'common/constants/apiUrls'
import { SAMPLE_UPLOAD_FILE } from 'common/constants/sampleData'
import { Context } from 'common/context/Context'

// Store
import { actions } from 'core/store'

// Hooks
import { useLogout } from 'core/hooks/api'
import { useLocalStorage } from 'core/hooks/storage'

// Styled Elements
import {
  HeaderWrapper,
  HeaderInner,
  HeaderBrand,
  HeaderBrandLogo,
  HeaderMenuNav,
  HeaderMenuNavList,
  HeaderMenuNavItem,
  HeaderMenuNavLink,
  HeaderRightNav,
  HeaderRightNavList,
  HeaderRightNavListItem,
  HeaderLogoutButton,
  ProfileInfoWrapper,
  ProfileFullName,
  ProfileUserName,
  ProfileUserRole,
  HeaderSideMenuButton,
  HeaderSideMenuIcon,
  NotificationIconImage,
  MenuIconImage,
  UserIconImage,
  UserCircledIconImage,
  LogoutIconImage,
  SubHeaderWrapper,
  SubHeaderContent,
  DropdownItemLink,
  FileText,
  FileWrapper,
  NotificationBell,
} from './Header.elements'

// Views
import {
  ContainerFluid,
  Dropdown,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Image,
  LoaderLine,
} from 'views/components'

// Map Redux Props
const mapStateToProps = (state) => state
const mapDispatchToProps = actions

const Header = (props) => {
  const { globalNotifToggler } = useContext(Context)

  // Destructure
  const { ui, actions, subHeader } = props
  const [userCredentials] = useLocalStorage('userCredentials')
  const [reportTitle, setReportTitle] = useState([])
  const [reportString, setReportString] = useState([])

  console.log('reportTitle', globalNotifToggler)

  // Store States
  const { showHeaderLoader } = ui

  // Store Actions
  const { openAppSidebarDrawer } = actions

  // Hooks
  const { logoutUser } = useLogout()

  // Functions
  const handleLogout = () => {
    logoutUser()
    localStorage.setItem('fileset', '')
  }

  const handleOpenSidebarDrawer = () => {
    openAppSidebarDrawer()
  }

  useEffect(() => {
    if (typeof window !== undefined) {
      setReportString(JSON.parse(localStorage.getItem('reportStrings')))
      setReportTitle(JSON.parse(localStorage.getItem('reportTitles')))
    }
  }, [globalNotifToggler])

  function s2ab(s) {
    var buf = new ArrayBuffer(s.length)
    var view = new Uint8Array(buf)
    for (var i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xff
    return buf
  }

  // DOWNLOAD REPORT
  const downloadReport = (index) => {
    // exportSampleFile(SAMPLE_UPLOAD_FILE[index])
    saveAs(
      new Blob([s2ab(reportString[index])], {
        type: 'application/octet-stream',
      }),
      reportTitle[index]
    )
  }

  return (
    <HeaderWrapper>
      <ContainerFluid>
        <HeaderInner>
          <HeaderBrand>
            <NavLink to="/">
              <HeaderBrandLogo>
                <Image src={BrandLogo} />
              </HeaderBrandLogo>
            </NavLink>
          </HeaderBrand>
          <HeaderMenuNav>
            <HeaderMenuNavList>
              <HeaderMenuNavItem>
                <HeaderMenuNavLink exact activeClassName="active" to="/">
                  Case Management
                </HeaderMenuNavLink>
              </HeaderMenuNavItem>
              <HeaderMenuNavItem>
                <HeaderMenuNavLink exact to="/whitelisting">
                  Address Monitoring
                </HeaderMenuNavLink>
              </HeaderMenuNavItem>
              <HeaderMenuNavItem>
                <HeaderMenuNavLink exact to="/monitoring">
                  Transaction Monitoring
                </HeaderMenuNavLink>
              </HeaderMenuNavItem>
            </HeaderMenuNavList>
          </HeaderMenuNav>
          <HeaderRightNav>
            <HeaderRightNavList>
              <HeaderRightNavListItem>
                <UncontrolledDropdown direction="down">
                  <DropdownToggle>
                    <NotificationIconImage>
                      <Image src={NotificationIcon} />
                      {reportString.length > 1 || globalNotifToggler === true ? (
                        <NotificationBell>!</NotificationBell>
                      ) : (
                        ''
                      )}
                    </NotificationIconImage>
                  </DropdownToggle>
                  <DropdownMenu left>
                    {reportTitle.length > 1 || globalNotifToggler === true ? (
                      <DropdownItem>
                        <FileWrapper>
                          <button>
                            <p>Data import completed</p>
                            <p> Download the report</p>
                          </button>
                          <FileText>
                            {reportTitle.length > 1 &&
                              reportTitle.map(
                                (data, index) =>
                                  reportTitle &&
                                  reportTitle[index] !== 'A' && (
                                    <>
                                      <span key={index} onClick={() => downloadReport(index)}>
                                        {data}
                                      </span>
                                    </>
                                  )
                              )}
                          </FileText>
                        </FileWrapper>
                      </DropdownItem>
                    ) : (
                      <DropdownItem>
                        <p> No notification. </p>
                      </DropdownItem>
                    )}
                  </DropdownMenu>
                </UncontrolledDropdown>
                {/* <Dropdown direction="down">
                  <DropdownToggle>
                    <NotificationIconImage>
                      <Image src={NotificationIcon} onClick={handleOpenData}/>
                    </NotificationIconImage>
                  </DropdownToggle>
                  <DropdownMenu left>
                    <DropdownItem>
                      <a target="_blank" href={EXTERNAL_URLS.FEEDBACK}>
                      Profile
                      </a>
                    </DropdownItem>
                  </DropdownMenu>
                </Dropdown> */}
              </HeaderRightNavListItem>
              <HeaderRightNavListItem>
                <UncontrolledDropdown direction="down">
                  <DropdownToggle>
                    <MenuIconImage>
                      <Image src={MenuIcon} />
                    </MenuIconImage>
                  </DropdownToggle>
                  <DropdownMenu left>
                    <DropdownItem>
                      <a target="_blank" href={EXTERNAL_URLS.FEEDBACK}>
                        Send feedback
                      </a>
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
              </HeaderRightNavListItem>
              <HeaderRightNavListItem>
                <UncontrolledDropdown direction="down">
                  <DropdownToggle>
                    <UserIconImage>
                      <Image src={UserIcon} />
                    </UserIconImage>
                  </DropdownToggle>
                  <DropdownMenu left>
                    <ProfileInfoWrapper>
                      <ProfileFullName>{userCredentials && userCredentials.FullName}</ProfileFullName>
                      <ProfileUserName>{userCredentials && userCredentials.User_Name}</ProfileUserName>
                      <ProfileUserRole>{userCredentials && userCredentials.User_Role}</ProfileUserRole>
                    </ProfileInfoWrapper>
                    <DropdownItem header>
                      <DropdownItemLink to="/user-profile">
                        <UserCircledIconImage>
                          <Image src={UserCircledIcon} height={20} width={20} />
                        </UserCircledIconImage>
                        Profile
                      </DropdownItemLink>
                    </DropdownItem>
                    <DropdownItem header onClick={handleLogout}>
                      <HeaderLogoutButton onClick={handleLogout}>
                        <LogoutIconImage>
                          <Image src={LogoutIcon} height={20} width={20} />
                        </LogoutIconImage>
                        Logout
                      </HeaderLogoutButton>
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
              </HeaderRightNavListItem>
            </HeaderRightNavList>
          </HeaderRightNav>
          <HeaderSideMenuButton>
            <HeaderSideMenuIcon onClick={handleOpenSidebarDrawer}>
              <Image src={TabPaneIcon} />
            </HeaderSideMenuIcon>
          </HeaderSideMenuButton>
        </HeaderInner>
      </ContainerFluid>
      {subHeader && (
        <SubHeaderWrapper>
          <ContainerFluid>
            <SubHeaderContent>{subHeader}</SubHeaderContent>
          </ContainerFluid>
        </SubHeaderWrapper>
      )}
      <LoaderLine show={showHeaderLoader} />
    </HeaderWrapper>
  )
}

// Default Props
Header.defaultProps = {
  subHeader: '',
  ui: {},
  actions: {},
}

// Proptypes Validation
Header.propTypes = {
  subHeader: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.node]),
  ui: PropTypes.shape({ showHeaderLoader: PropTypes.bool }),
  actions: PropTypes.shape({ openAppSidebarDrawer: PropTypes.func }),
}

export default connect(mapStateToProps, mapDispatchToProps)(Header)
